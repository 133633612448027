import React, { Fragment, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setOptionAction } from '../../../Actions/option';
import { ChangeRotate } from '../../../Actions/rotate';
import { decreamentScale, increamentScale } from '../../../Actions/scale';
import { setLoading } from '../../../Actions/setLoading';
import { sendFeature, sendRotate, sendScale } from '../../../utils/sendFeature';


const ToolsMob = ({ uniqeValue,
    uniqeTypeValue,
    uniqeColorValue,
    setSizeFilter,
    setColorFilter,
    setTypeFilter,
    sizeFilter,
    colorFilter,
    typeFilter,

}) => {
    //all state
    const tools = useSelector(state => state.feature);
    const picState = useSelector(state => state.picture);
    const scale = useSelector(state => state.scale);
    const option = useSelector(state => state.option);
    const rotate = useSelector(state => state.rotate);

    //use useRef hook for stop call function at first rendering
    const scaleDidMount = useRef(false);
    const rotateDidMount = useRef(false);
    const featureDidMount = useRef(false);
    const dispatch = useDispatch();

    //use useEffect hook for send updated state after dispatch
    useEffect(() => {
        if (!scaleDidMount.current) {
            return scaleDidMount.current = true;
        }
        handleScale();
    }, [scale]);

    useEffect(() => {
        if (!rotateDidMount.current) {
            return rotateDidMount.current = true;
        }
        handleFeature();
    }, [option]);

    useEffect(() => {
        if (!featureDidMount.current) {
            return featureDidMount.current = true;
        }
        handleRotate();
    }, [rotate]);


    //all function to send all state to server 
    const handleRotate = () => {
        dispatch(setLoading());
        sendRotate(option, rotate, scale, tools.featur_value, picState.token, picState.procode, picState.object_id);
    }

    const handleScale = () => {
        dispatch(setLoading());
        sendScale(option, scale, tools.featur_value, picState.token, picState.procode, picState.object_id);
    }

    const handleFeature = () => {
        dispatch(setLoading());
        sendFeature(option, rotate, scale, tools.featur_value, picState.token, picState.procode, picState.object_id);
    }


    return (
        <>
            <Fragment>
                <div className='w-100 tools-m-container'>
                    <div style={{ direction: "ltr" }} className='tools-feature w-100 d-flex flex-wrap'>
                        <div className='filter-item-tile mb-2 px-1'>
                            <div>
                                <p className='title-filter-text'>size :</p>
                                <Form.Select className='w-100 drop-mob-filter px-1 text-start' onChange={e => setSizeFilter(e.target.value)} >
                                    <option value="All">All</option>
                                    {uniqeValue.map(value => (
                                        <option selected={value === sizeFilter} value={value}>{value}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className='filter-item-tile mb-2 px-1'>
                            <div>
                                <p className='title-filter-text'>type :</p>
                                <Form.Select className='w-100 drop-mob-filter px-1 text-start' onChange={e => setTypeFilter(e.target.value)}  >
                                    <option value="All">All</option>
                                    {uniqeTypeValue.map(value => (
                                        <option selected={value === typeFilter} value={value}>{value}</option>
                                    ))}
                                </Form.Select>
                            </div>

                        </div>
                        <div className='filter-item-tile mb-2 px-1'>
                            <div>
                                <p className='title-filter-text'>color :</p>
                                <Form.Select className='w-100 drop-mob-filter px-1 text-start' onChange={e => setColorFilter(e.target.value)} >
                                    <option value="All">All</option>
                                    {uniqeColorValue.map(value => (
                                        <option selected={value === colorFilter}>{value}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>

    );
}

export default ToolsMob;


{/* <div className='w-50 m-1 tools-sc-rt'>
                        <div className='d-flex'>
                            <Button className='me-1 w-50 p-3 shadow' variant='none' onClick={() => {
                                dispatch(decreamentScale());
                            }}><i class="bi bi-zoom-out"></i></Button>
                            <Button className='w-50 p-3 shadow' variant='none' onClick={() => {
                                dispatch(increamentScale());
                            }}><i class="bi bi-zoom-in"></i></Button>
                        </div>
                        <div className='mt-2'>
                            <Button className='w-100 shadow-sm p-3 shadow' variant='none' disabled onClick={() => {
                                dispatch(ChangeRotate());
                            }}>Rotate<i className='fa fa-rotate-left me-3'></i></Button>
                        </div>
                    </div> */}