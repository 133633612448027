import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '../Actions/setLoading';
import Ariis from './Ariis';
import { TextureProvider } from '../context';


const App = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        setTimeout(() => { dispatch(setLoading()) }, 1500)
    }, [])

    return (
        <>
            <TextureProvider>
                <BrowserRouter>
                    <Ariis />
                </BrowserRouter>
            </TextureProvider>

        </>
    );
}

export default App;
