import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => {

    return (
        <div className='loading'>
            <ReactLoading type='bars' color='#010c34' height={100 + '%'} width={200} />
            <div className='loading-caption'>
                <p>Powered By DECOEASE</p>
            </div>
        </div>
    );
}

export default Loading;