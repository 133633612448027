import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { downloadImage } from '../../../utils/downloadImage';
import { isEmpty } from 'lodash';
import FormImage from '../../common/FormImage';
import { useTextureContext } from '../../../context';

const Header = ({ handleShowCmpare }) => {
    const textures = useSelector(state => state.texture);
    const picState = useSelector(state => state.picture);
    const { mainTileName, mainTileSize, mainTileType } = useTextureContext();
    return (
        <>
            <div className='w-100 d-flex web-header'>
                <div className='header-wrapper text-right ps-4'>
                    <label htmlFor='file-input'>
                        <a className='btn bg-light py-2 px-2 m-1 text-dark shadow-sm'><i className='fa fa-camera ms-2 pt-1'></i>Retake</a>
                    </label>
                    <Button variant='light' className='p-2 m-1 text-dark shadow-sm d-none'>Face-to-face stores</Button>
                </div>
                <div className='header-wrapper header-wrapper-logo text-center'>
                    <img src={"../assets/images/decoease-logo.png"} alt="logo-company" />
                </div>
                <div className='header-wrapper text-start pe-4'>
                    <Badge pill bg='none' className='p-2 m-1' onClick={handleShowCmpare}>
                        <img src="assets/images/compare_icon.png" alt='Compare-icon' width='38' height='38' />
                    </Badge>
                    <Badge pill bg='none' className='p-2 m-1' onClick={e => downloadImage(picState.img, picState.name)}>
                        <img src='assets/images/download-icon.png' alt='download-icon' />
                    </Badge>
                    <Badge pill style={{ backgroundColor: 'var(--themeColor)' }} bg='none' className='p-2 m-1 text-white d-none'>
                        <img src='assets/images/cart-icon.png' alt='cart' />
                    </Badge>
                </div>
                <FormImage />
            </div>
            {
                mainTileName &&
                <p className='text-center info-main-tail mb-3' style={{ direction: "ltr", fontSize: ".8rem" }}>{`${mainTileName.charAt(0).toUpperCase()}${mainTileName.slice(1)}`} / {mainTileSize} / {`${mainTileType.charAt(0).toUpperCase()}${mainTileType.slice(1)}`}</p>
            }
        </>
    );
}

export default Header;