import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changedPicture } from '../../../Actions/changePic';
import { allFeatureTexture } from '../../../Actions/feature';
import { clearOptionAction } from '../../../Actions/option';
import { setLoading } from '../../../Actions/setLoading';
import { setOldImg } from '../../../Actions/setOldImg';
import { changeTexture } from '../../../Services/transferData';
import { historySelectedSurface } from '../../../utils/utils';
import { useTextureContext } from '../../../context';

const TextureMob = ({ txture }) => {
    // TODO:
    const picState = useSelector(state => state.picture);
    const selectedMarker = useSelector(state => state.selectedMarker);
    const { setMainTileName, setMainTileSize, setMainTileType, setOldPic, setNewPic, oldpic, NewPic } = useTextureContext();
    const [allProduct, setAllProduct] = useState(txture)

    useEffect(() => {
        setAllProduct(txture)
    }, [txture])

    // TODO:
    const dispatch = useDispatch();

    // TODO:
    const handleSendTexture = (object_id, featur_value, translate, procode) => {
        const data_feature = {
            featur_value,
            translate
        }
        dispatch(allFeatureTexture(data_feature));
        historySelectedSurface(selectedMarker, object_id);//
        sendTexture(object_id, featur_value, procode, picState.token);
    }


    // TODO:
    const sendTexture = async (object_id, featur_value, procode, token) => {
        dispatch(setOldImg(picState.img));

        const edited_featur_value = {};
        let x = JSON.parse(window.localStorage.getItem("history"))

        for (var key in featur_value) {
            edited_featur_value[key] = featur_value[key][0];
        }

        const featureTexture = {
            // object_id,
            oldPoint: JSON.parse(localStorage.getItem("history")),
            point: {
                points: selectedMarker,
                object_id
            },
            featur_value: edited_featur_value,
            procode,
            token
        }

        try {
            const { data, status } = await changeTexture(featureTexture);
            if (status === 200) {
                for (let i = 0; i < selectedMarker.length; i++) {
                    x[selectedMarker[i]] = object_id
                    localStorage.setItem('history', JSON.stringify(x))
                }
                dispatch(changedPicture(data.data));
                dispatch(clearOptionAction());
                setTimeout(() => dispatch(setLoading()), 2000);
            }
        } catch (error) {
            dispatch(setLoading());
        }
    }

    const handleClick = (index) => {
        const newProducts = [...allProduct];
        const clickedProduct = newProducts.splice(index, 1)[0];
        const defaultProduct = newProducts.splice(0, 1)[0]
        newProducts.unshift(clickedProduct);
        newProducts.unshift(defaultProduct)
        setAllProduct(newProducts);
        const textureWrapper = document.querySelector('.texture-wrapper');
        if (textureWrapper) {
            textureWrapper.scrollLeft = 0;
        }
    };


    return (
        <div className='texture-wrapper d-flex'>
            {allProduct?.length > 0 ?
                allProduct.map((x, index) => (
                    <div key={x.object_id} className='texture-element-m' onClick={() => {
                        handleSendTexture(x.object_id, x.featur_value, x.translate, x.procode);
                        handleClick(index)
                        dispatch(setLoading());
                        setMainTileName(x.name)
                        setMainTileSize(x.featur_value?.size[0])
                        setMainTileType(x.featur_value?.tile_type[0])
                        if (!oldpic && !NewPic) {
                            setOldPic(x.name)
                        } else if (oldpic && !NewPic) {
                            setNewPic(x.name)
                        } else if (oldpic && NewPic) {
                            setOldPic(NewPic)
                            setNewPic(x.name)
                        }
                    }}>
                        <article>
                            <img src={x.ticon} alt={x.name} className="img-texture-m" />
                            <p className='p-2 texture-caption-m'>
                                {x.name}
                            </p>
                        </article>
                        <p className={picState.object_id === x.object_id ? "check-texture d-block" : "d-none"}><i class="fa fa-check"></i></p>
                    </div>
                )) :
                <p className='not-found-result'>No results found</p>
            }
        </div>
    );
}

export default TextureMob;