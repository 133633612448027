import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changedPicture } from '../../../../Actions/changePic';
import { allFeatureTexture } from '../../../../Actions/feature';
import { clearOptionAction } from '../../../../Actions/option';
import { setLoading } from '../../../../Actions/setLoading';
import { setOldImg } from '../../../../Actions/setOldImg';
import { changeTexture } from '../../../../Services/transferData';
import { historySelectedSurface } from '../../../../utils/utils';

const TextureSidebar = ({ txtureee, hSidebar }) => {

    const picState = useSelector(state => state.picture);
    const selectedMarker = useSelector(state => state.selectedMarker);


    const dispatch = useDispatch();

    const handleSendTexture = (object_id, featur_value, translate, procode) => {
        const data_feature = {
            featur_value,
            translate
        }
        dispatch(allFeatureTexture(data_feature));
        historySelectedSurface(selectedMarker, object_id);//
        sendTexture(object_id, featur_value, procode, picState.token);
    }

    // TODO:
    const sendTexture = async (object_id, featur_value, procode, token) => {
        dispatch(setOldImg(picState.img));

        const edited_featur_value = {};
        let x = JSON.parse(window.localStorage.getItem("history"))

        for (var key in featur_value) {
            edited_featur_value[key] = featur_value[key][0];
        }

        const featureTexture = {
            // object_id,
            oldPoint: JSON.parse(localStorage.getItem("history")),
            point: {
                points: selectedMarker,
                object_id
            },
            featur_value: edited_featur_value,
            procode,
            token
        }

        try {
            const { data, status } = await changeTexture(featureTexture);
            if (status === 200) {
                for (let i = 0; i < selectedMarker.length; i++) {
                    x[selectedMarker[i]] = object_id
                    localStorage.setItem('history', JSON.stringify(x))
                }
                dispatch(changedPicture(data.data));
                dispatch(clearOptionAction());
                setTimeout(() => dispatch(setLoading()), 2000);
            }
        } catch (error) {
            dispatch(setLoading());
        }
    }


    return (
        <>
            <div className='texture-container-sidebar py-3 w-100'>
                {txtureee.map(x => (
                    <div key={x.object_id} className='texture-element-sidebar' onClick={() => {
                        handleSendTexture(x.object_id, x.featur_value, x.translate, x.procode);
                        dispatch(setLoading());
                        hSidebar(false)
                    }}>
                        <article>
                            <img src={x.ticon} alt={x.name} className="img-texture-sidebar" />
                            <p className='p-2 texture-caption-sidebar'>
                                {x.name}
                            </p>
                        </article>
                        <p className={picState.object_id === x.object_id ? "check-texture d-block" : "d-none"}><i class="fa fa-check"></i></p>
                    </div>
                ))}
            </div>
        </>
    );
}

export default TextureSidebar;