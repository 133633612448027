import {includes,omit} from "lodash";

// TODO: get query params string 'procode' in url
export const getQueryParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const query_string = params.get('procode');
    return query_string;
}


// TODO: get user location
export const getLocation = () => {
    let userLocation = {};
    if("geolocation" in navigator){
        navigator.geolocation.getCurrentPosition((location) => {
            userLocation.latitude = location.coords.latitude;
            userLocation.longitude = location.coords.longitude;
        },null);
    }
    return userLocation;
}

// TODO:
export const historySelectedSurface = (selectedMarker,objId) => {
    for (let i = 0; i < selectedMarker.length; i++) {
        const history_changed_points = JSON.parse(window.localStorage.getItem("history"));
        if(includes(Object.keys(history_changed_points),selectedMarker[i].toString())){
            console.log("stp1");
            console.log(selectedMarker[i]);
            let new_history_changed_points = omit(history_changed_points,[selectedMarker[i].toString()])
            window.localStorage.setItem("history",JSON.stringify(new_history_changed_points))
        }
    }
}
