import _ from 'lodash';

export const searchTexture = (texture,textureName) => {
    if(textureName === ""){
        return texture;
    }else if(textureName !== "") {
        return _.filter(texture,(x) => {
            return x.name.toLowerCase().includes(textureName.toLowerCase());
        })
    }
}