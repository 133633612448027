import config from './config.json';
import http from './httpService';


export const uploadImage = (data) => {
    return http.post(`${config.ariisapi}/log/upload/`,data);
};

export const getTexture = () => {
    return http.get(`${config.ariisapi}/object/objfac/`);
};

export const changeTexture = (data) => {
    return http.post(`${config.ariisapi}/log/newdistinction/`,data);
}

export const sendLocation = (data) => {
    return http.post(`${config.ariisapi}/log/upload/`,data);
}


