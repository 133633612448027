import { changedPicture } from "../Actions/changePic";
import { clearOptionAction } from "../Actions/option";
// import { cleartScale } from "../Actions/scale";
import { setLoading } from "../Actions/setLoading";
import { setOldImg } from "../Actions/setOldImg";
import { changeTexture } from "../Services/transferData";
import { store } from '../Store';

export const sendTexture = async (object_id,featur_value,procode,token) => {
    const picState = store.getState().picture;
    store.dispatch(setOldImg(picState.img));
    
    const edited_featur_value = {};

    for (var key in featur_value){
        edited_featur_value[key] = featur_value[key][0];
    }


    const featureTexture = {
        object_id,
        featur_value:edited_featur_value,
        procode,
        token
    }


    try {
        const {data,status} = await changeTexture(featureTexture);
        if (status === 200) {
            store.dispatch(changedPicture(data.data));
            store.dispatch(clearOptionAction());
            setTimeout(() => store.dispatch(setLoading()),2000);
        }
    } catch (error) {
        store.dispatch(setLoading());
    }
}