
export const scaleReducer = (state = 5,action) => {
    switch (action.type) {
        case "INCREAMENT_SCALE":
            if(state < 9) {
                return state + 1;
            }else{
                return state = 9;
            }
        case "DECREAMENT_SCALE":
            if(state > 1) {
                return state - 1;
            }else{
                return state = 1;
            }
        case "CLEAR_SCALE":
            return state = 5;
        default:
            return state
    }
}