import React, { Fragment, useState } from 'react';
import { lvl1_menu, lvl2_menu, lvl3_menu } from '../../../utils/checkType';
import { Button } from 'react-bootstrap';
import { allFeatureTexture } from '../../../Actions/feature';
import { sendTexture } from '../../../utils/sendTexture';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../Actions/setLoading';
import _ from 'lodash';

const MenuTextureMob = ({ txture }) => {
    const picState = useSelector(state => state.picture);
    const dispatch = useDispatch();

    const [counter, setCounter] = useState(0);
    const [modelParent, setModelParent] = useState(0);
    const [state, setState] = useState({});

    const level_1 = lvl1_menu(txture);
    const level_2 = lvl2_menu(txture);
    const level_3 = lvl3_menu(txture);
    let object = {};
    if (counter === 0) {
        object = { ...level_1 };
    } else if (counter === 1) {
        object = state;
    } else if (counter === 2) {
        object = state;
    }

    const handleStageTexture = (id_obj, level) => {
        if (level === 1) {
            const a = _.filter(level_2.m, ['model_parent', id_obj]);//menu
            const b = _.filter(level_2.o, ['model_parent', id_obj]);//object
            const c = { m: a, o: b };
            setState({ ...c });
        }
        if (level === 2) {
            const a = _.filter(level_3.m, ['model_parent', id_obj]);//menu
            const b = _.filter(level_3.o, ['model_parent', id_obj]);//object
            const c = { m: a, o: b };
            setState({ ...c });
        }
    }

    const handleBackMenu = () => {
        if (modelParent === 1) {
            setState({ ...level_1 });
            if (counter > 0) setCounter(counter - 1);
        }
        if (modelParent === 2) {
            const a = _.filter(level_2.m, ['lvl', 2]);//menu
            // const b = _.filter(level_2.o, ['lvl', 2]);//object
            const b = [];//object
            const c = { m: a, o: b };
            setState({ ...c });
            if (counter > 0) setCounter(counter - 1);
        }
    }
    const handleSendTexture = (object_id, featur_value, translate, procode) => {
        const data_feature = {
            featur_value,
            translate
        }
        dispatch(allFeatureTexture(data_feature));
        sendTexture(object_id, featur_value, procode, picState.token);
    }

    return (
        <Fragment>
            <div className='menu-container'>
                <div className='back-menu w-100 my-1 px-2'>
                    <Button variant='none' className='w-100 text-light' onClick={handleBackMenu}><i className="fa fa-angle-left"></i></Button>
                </div>
                <div className='texture-wrapper d-flex'>
                    {object.m.map(x => (
                        <div key={x.object_id} className='texture-element-m' onClick={() => {
                            setCounter(counter + 1);
                            setModelParent(x.lvl);
                            handleStageTexture(x.object_id, x.lvl);
                        }}>
                            <article>
                                <img src={x.ticon} alt={x.name} className="img-texture-m" />
                                <p className='p-2 texture-caption-m'>
                                    {x.name}
                                </p>
                            </article>
                        </div>
                    ))}
                    {object.o.map(x => (
                        <div key={x.object_id} className='texture-element-m' onClick={() => {
                            handleSendTexture(x.object_id, x.featur_value, x.translate, x.procode);
                            dispatch(setLoading());
                        }}>
                            <article>
                                <img src={x.ticon} alt={x.name} className="img-texture-m" />
                                <p className='pt-2 texture-caption-m'>
                                    {x.name}
                                </p>
                            </article>
                            <p className={picState.object_id === x.object_id ? "check-texture d-block" : "d-none"}><i class="fa fa-check"></i></p>
                        </div>
                    ))}
                </div>
            </div>

        </Fragment>
    );
}

export default MenuTextureMob;