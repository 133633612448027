import _ from 'lodash';

export const sortTexture = (texture) => {
    const info = texture[0];
    if (_.every(info,["type_obj","m"]) === true){
        const obj_1 = _.filter(texture[1], ['type_model', "o"]);
        const obj_2 = _.filter(texture[2], ['type_model', "o"]);
        const obj_3 = _.filter(texture[3], ['type_model', "o"]);
        return _.unionWith(obj_1,obj_2,obj_3, _.isEqual);
    }else{
        return texture[1];
    }

};