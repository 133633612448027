import axios from "axios";
import { toast } from "react-toastify";


const url = window.location.origin;
switch (url) {
    case "https://parquet.decoease.co.uk":
        axios.defaults.headers.post["Authorization"] = "Bearer ZGVjb3RlazpEZWNvdGVrQDEyMzparquet";//marjan
        axios.defaults.headers.get["Authorization"] = "Bearer ZGVjb3RlazpEZWNvdGVrQDEyMzparquet";//marjan
        break;
    case "https://carpet.decoease.co.uk":
        axios.defaults.headers.post["Authorization"] = "Bearer ZGVjb2Vhc2UtcnVnOkRlY29lYXNlQDEyM";//aratile
        axios.defaults.headers.get["Authorization"] = "Bearer ZGVjb2Vhc2UtcnVnOkRlY29lYXNlQDEyM";//aratile
        break;
    case "https://tile.decoease.co.uk":
        axios.defaults.headers.post["Authorization"] = "Bearer ZGVjb2Vhc2U6RGVjb2Vhc2VAMTIzNDU2";//doroudian
        axios.defaults.headers.get["Authorization"] = "Bearer ZGVjb2Vhc2U6RGVjb2Vhc2VAMTIzNDU2";//doroudian
        break;
    default:
        // axios.defaults.headers.post["Authorization"] = "Bearer ZW5naW5lOkVuZ2luZUAxMjM0NTY=wall";//ariis
        // axios.defaults.headers.get["Authorization"] = "Bearer ZW5naW5lOkVuZ2luZUAxMjM0NTY=wall";//ariis
        axios.defaults.headers.post["Authorization"] = "Bearer ZGVjb3RlazpEZWNvdGVrQDEyMzparquet";//marjan
        axios.defaults.headers.get["Authorization"] = "Bearer ZGVjb3RlazpEZWNvdGVrQDEyMzparquet";//marjan
        break;
}






// axios.defaults.headers.get["Authorization"] = "Bearer ZGVjb2Vhc2U6RGVjb2Vhc2VAMTIzNDU2";//marjan
// axios.defaults.headers.post["Authorization"] = "Bearer ZGVjb2Vhc2U6RGVjb2Vhc2VAMTIzNDU2";//marjan

axios.interceptors.response.use(null, error => {
    const exeptedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if (!exeptedError) {
        toast.error("A problem has occurred on the server side", {
            position: "bottom-right",
            autoClose: 1500,
            closeButton: true,
            closeOnClick: true
        });
    };
    return Promise.reject(error);
});

export default {
    post: axios.post,
    get: axios.get,
    put: axios.put,
    delete: axios.delete
};