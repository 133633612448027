import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { uploadImage } from '../../Services/transferData';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Actions/setLoading';
import { changedPicture } from '../../Actions/changePic';
import { allFeatureTexture } from '../../Actions/feature';
import { setOldImg } from '../../Actions/setOldImg';
import { Form } from 'react-bootstrap';
import Compress from '../../compress';
import { getPointsAction } from '../../Actions/pointsActions';

const FormImage = ({ queryParams, location }) => {
    const selectedMarker = useSelector(state => state.selectedMarker);

    const Redirect = useNavigate();
    const dispatch = useDispatch();

    const changeInputValue = (evt) => {
        const compress = new Compress();
        const files = [...evt.target.files]
        compress.compress(files, {
            size: 4, // the max size in MB, defaults to 2MB
            quality: .75, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
            rotate: false, // See the rotation section below
        }).then((results) => {
            const output = results[0];
            const file = Compress.convertBase64ToFile(output.data, output.ext);
            let srcValue;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                srcValue = reader.result;
                const res = srcValue;
                submitForm(res);
            };
            dispatch(setLoading());
        });
    };


    const submitForm = async (dataImage) => {
        const formData = new FormData();
        formData.append('img', dataImage);
        if (queryParams && queryParams !== null) formData.append('procode', queryParams);
        if (location) formData.append('location', JSON.stringify(location));
        try {
            const { data, status } = await uploadImage(formData);
            if (status === 200) {
                dispatch(setLoading());
                dispatch(setOldImg(dataImage));
                dispatch(changedPicture(data.data));
                localStorage.setItem("history", JSON.stringify({ [selectedMarker[0]]: data.data.object_id }))
                dispatch(getPointsAction(data.points))
                dispatch(allFeatureTexture(data.featur));
            }
            Redirect('/ariis-w');
        } catch (err) {
            toast.error("The photo could not be uploaded", {
                position: "bottom-right",
                autoClose: 1500,
                closeButton: true,
                closeOnClick: true
            });
            setTimeout(() => { dispatch(setLoading()) }, 1000)
        }
    };
    return (
        <Form className='d-none' method='post' onSubmit={submitForm}>
            <input type="file" id='file-input' accept="image/*" onChange={changeInputValue} />
            <input type='hidden' />
        </Form>
    );
}

export default FormImage;