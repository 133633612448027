import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../Actions/setLoading';
import { allFeatureTexture } from '../../../Actions/feature';
import { setOldImg } from '../../../Actions/setOldImg';
import { changeTexture } from '../../../Services/transferData';
import { changedPicture } from '../../../Actions/changePic';
import { clearOptionAction } from '../../../Actions/option';
import { historySelectedSurface } from '../../../utils/utils';
import { Form } from 'react-bootstrap';
import { useTextureContext } from '../../../context';

const Texrure = ({ texture }) => {
    const picState = useSelector(state => state.picture);
    const selectedMarker = useSelector(state => state.selectedMarker);

    const [sizeFilter, setSizeFilter] = useState('')
    const [colorFilter, setColorFilter] = useState('')
    const [typeFilter, setTypeFilter] = useState('')
    const [allTile, setTile] = useState(texture)
    const dispatch = useDispatch();
    const { setMainTileName, setMainTileSize, setMainTileType, setOldPic, setNewPic, oldpic, NewPic } = useTextureContext();

    const handleSendTexture = (object_id, featur_value, translate, procode) => {
        const data_feature = {
            featur_value,
            translate
        }
        dispatch(allFeatureTexture(data_feature));
        historySelectedSurface(selectedMarker, object_id);//
        sendTexture(object_id, featur_value, procode, picState.token);
    }


    const sendTexture = async (object_id, featur_value, procode, token) => {
        dispatch(setOldImg(picState.img));

        const edited_featur_value = {};
        let x = JSON.parse(window.localStorage.getItem("history"))

        for (var key in featur_value) {
            edited_featur_value[key] = featur_value[key][0];
        }

        const featureTexture = {
            // object_id,
            oldPoint: JSON.parse(localStorage.getItem("history")),
            point: {
                points: selectedMarker,
                object_id
            },
            featur_value: edited_featur_value,
            procode,
            token
        }

        try {
            const { data, status } = await changeTexture(featureTexture);
            if (status === 200) {
                for (let i = 0; i < selectedMarker.length; i++) {
                    x[selectedMarker[i]] = object_id
                    localStorage.setItem('history', JSON.stringify(x))
                }
                dispatch(changedPicture(data.data));
                dispatch(clearOptionAction());
                setTimeout(() => dispatch(setLoading()), 2000);
            }
        } catch (error) {
            dispatch(setLoading());
        }
    }

    const filterSize = texture.map(element => (element.featur_value.size))
    const flattenedArray = filterSize.flat();
    const cleanSizeArray = flattenedArray.filter(type => type !== undefined);
    const uniqeValue = [...new Set(cleanSizeArray)]


    const filterType = texture.map(element => (element.featur_value.tile_type))
    const flattTypeArray = filterType.flat();
    const cleanTypeArray = flattTypeArray.filter(type => type !== undefined);
    const uniqeTypeValue = [...new Set(cleanTypeArray)]


    const filterColor = texture.map(element => (element.featur_value.color))
    const flatColortArray = filterColor.flat();
    const cleanColorArray = flatColortArray.filter(type => type !== undefined);
    const uniqeColorValue = [...new Set(cleanColorArray)]



    useEffect(() => {
        filterHandler()
    }, [sizeFilter, colorFilter, typeFilter])

    const filterHandler = () => {
        let filteredResult = [...texture];

        if (sizeFilter !== "All" && sizeFilter) {
            filteredResult = filteredResult.filter(element => element.featur_value?.size?.includes(sizeFilter));
        }

        if (colorFilter !== "All" && colorFilter) {
            filteredResult = filteredResult.filter(element => element.featur_value?.color?.includes(colorFilter));
        }

        if (typeFilter !== "All" && typeFilter) {
            filteredResult = filteredResult.filter(element => element.featur_value?.tile_type?.includes(typeFilter));
        }

        setTile(filteredResult);
    };

    return (
        <>
            {
                allTile?.length > 0 ?
                    <div className='texture-container w-100'>
                        {
                            allTile?.map((x, index) => (
                                <div key={index} className='texture-element' onClick={() => {
                                    handleSendTexture(x.object_id, x.featur_value, x.translate, x.procode);
                                    dispatch(setLoading());
                                    setMainTileName(x.name)
                                    setMainTileSize(x.featur_value?.size[0])
                                    setMainTileType(x.featur_value?.tile_type[0])
                                    if (!oldpic && !NewPic) {
                                        setOldPic(x.name)
                                    } else if (oldpic && !NewPic) {
                                        setNewPic(x.name)
                                    } else if (oldpic && NewPic) {
                                        setOldPic(NewPic)
                                        setNewPic(x.name)
                                    }
                                }}>
                                    <article>
                                        <img src={x.ticon} alt={x.name} className="img-texture" />
                                        <p className='p-2 texture-caption'>
                                            {x.name}
                                        </p>
                                    </article>
                                    <p className={picState.object_id === x.object_id ? "check-texture d-block" : "d-none"}><i className="fa fa-check"></i></p>
                                </div>
                            ))
                        }

                    </div> :
                    <div className='not-found-result-web'>
                        <p style={{ width: "100%" }}>No results found</p>
                    </div>
            }

            <div className='filters_wrapper d-flex flex-wrap mt-3'>
                <div className='filter-item-tile mb-2 px-1'>
                    <div>
                        <p className='title-filter-text'>Size :</p>
                        <Form.Select className='w-100 text-start '
                            onChange={e => {
                                setSizeFilter(e.target.value)
                            }
                            }>
                            <option value={"All"}>All</option>
                            {uniqeValue.map(value => (
                                <option value={value}>{value}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className='filter-item-tile mb-2 px-1'>
                    <div>
                        <p className='title-filter-text'>Type :</p>
                        <Form.Select className='w-100 text-start' onChange={e => {
                            setTypeFilter(e.target.value)
                        }} >
                            <option value={"All"}>All</option>
                            {uniqeTypeValue.map(value => (
                                <option value={value}>{value}</option>
                            ))}
                        </Form.Select>
                    </div>

                </div>
                <div className='filter-item-tile mb-2 px-1'>
                    <div>
                        <p className='title-filter-text'>Color :</p>
                        <Form.Select className='w-100 text-start' onChange={e => {
                            setColorFilter(e.target.value)
                        }}>
                            <option value={"All"}>All</option>
                            {uniqeColorValue.map(value => (
                                <option value={value}>{value}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
            </div>
        </>

    );
}


export default Texrure;





