import React, { useState } from 'react';
import Filter from './Filter';
import Search from './Search';
import Texrure from './Texture';
import Tools from './Tools';
import { useSelector } from 'react-redux';
import MenuTexture from './MenuTexture';
import { checkType } from '../../../utils/checkType';
import { sortTexture } from '../../../utils/sortTexture';
import { searchTexture } from '../../../utils/searchTexture';
import SearchTexture from './SearchTexture';
import _ from 'lodash';

const SideRight = () => {
    const [search, setSearch] = useState("");
    const [s, setS] = useState(false);

    const textures = useSelector(state => state.texture);
    const surface_type = useSelector(state => state.surfaceType);
    const sort_texture_by_type = textures[1]?.filter(ele => _.includes(ele.type, surface_type))
    const check_Type_Model = checkType(textures);
    const takeTexture = sortTexture(textures);
    const searchState = searchTexture(takeTexture, search);

    const handleSearchTexture = (event) => {
        setSearch(event);
        if (event !== "") {
            setS(true);
        } else {
            setS(false);
        }
    }

    let isTextureComponent = null;
    if (check_Type_Model) {
        isTextureComponent = <MenuTexture texture={textures} />
    } else {
        isTextureComponent = <Texrure texture={sort_texture_by_type} />
    }

    return (

        <div className='sidebar-right-container w-100 h-100 position-relative'>
            <Search searchItem={handleSearchTexture} />
            <Filter />
            {s ? <SearchTexture texture={searchState} /> : isTextureComponent}
            {/* <Tools texture={textures} /> */}
        </div>
    );
}
export default SideRight;