// TextureContext.js
import React, { createContext, useState, useContext } from 'react';

const TextureContext = createContext();

export const useTextureContext = () => useContext(TextureContext);

export const TextureProvider = ({ children }) => {
    const [mainTileName, setMainTileName] = useState('');
    const [mainTileSize, setMainTileSize] = useState('');
    const [mainTileType, setMainTileType] = useState('');
    const [oldpic, setOldPic] = useState("");
    const [NewPic, setNewPic] = useState("");

    return (
        <TextureContext.Provider
            value={{
                mainTileName,
                setMainTileName,
                mainTileSize,
                setMainTileSize,
                mainTileType,
                setMainTileType,
                oldpic,
                setOldPic,
                NewPic,
                setNewPic
            }}
        >
            {children}
        </TextureContext.Provider>
    );
};
