import React, { useState, Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import BodySidebar from './filters/BodySidebar';
import FooterSidebar from './filters/FooterSidebar';
import HeaderSidebar from './filters/HeaderSidebar';
import TextureMob from './TextureMob';
import ToolsMob from './ToolsMob';
import TextureSidebar from './filters/TextureSidebar';
import { checkType } from '../../../utils/checkType';
import MenuTextureMob from './MenuTextureMob';
import { sortTexture } from '../../../utils/sortTexture';
import { searchTexture } from '../../../utils/searchTexture';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const Footer = ({ mainState }) => {

    const [footerBody, setFooterBody] = useState(false);
    const [showTxture, setShowTxture] = useState(true);
    const [showTools, setShowTools] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [txtureSearch, setTxtureSearch] = useState(false);
    const [search, setSearch] = useState("");

    const [sizeFilter, setSizeFilter] = useState('')
    const [colorFilter, setColorFilter] = useState('')
    const [typeFilter, setTypeFilter] = useState('')
    const [mainValue, setMainValue] = useState(mainState[1])
    const [title, setTitle] = useState("Select Product")

    const showBodyFooter = () => {
        setFooterBody(!footerBody);
    };
    const handleShowTxture = () => {
        setShowTxture(true);
        setShowTools(false);
    };
    const handleShowTools = () => {
        setShowTxture(false);
        setShowTools(true);
    };
    const handleShowSidebar = () => {
        setShowSidebar(true);
    };
    const handleHideSidebar = ff => {
        if (ff === false) setShowSidebar(ff);
    };
    const handleShowTextureSearch = (event) => {
        if (event) {
            setTxtureSearch(true);
        } else {
            setTxtureSearch(false);
        }
    }

    const handleSearchTecture = (event) => {
        setSearch(event);
    }
    // -----------------------------------------------------------------------
    const textures = useSelector(state => state.texture);
    const surface_type = useSelector(state => state.surfaceType);
    const sort_texture_by_type = textures[1]?.filter(ele => _.includes(ele.type, surface_type))

    const check_Type_Model = checkType(mainState);
    const takeTexture = sortTexture(mainState);
    const searchState = searchTexture(takeTexture, search);


    const filterSize = takeTexture.map(element => (element?.featur_value?.size))
    const flattenedArray = filterSize.flat();
    const cleanSizeArray = flattenedArray.filter(type => type !== undefined);
    const uniqeValue = [...new Set(cleanSizeArray)]


    const filterType = takeTexture.map(element => (element?.featur_value?.tile_type))
    const flattTypeArray = filterType.flat();
    const cleanTypeArray = flattTypeArray.filter(type => type !== undefined);
    const uniqeTypeValue = [...new Set(cleanTypeArray)]


    const filterColor = takeTexture.map(element => (element?.featur_value?.color))
    const flatColortArray = filterColor.flat();
    const cleanColorArray = flatColortArray.filter(type => type !== undefined);
    const uniqeColorValue = [...new Set(cleanColorArray)]


    useEffect(() => {
        filterHandler()
    }, [sizeFilter, colorFilter, typeFilter])


    const filterHandler = () => {
        let filteredResult = mainState ? mainState[1] : "";
        if (sizeFilter !== "All" && sizeFilter) {
            filteredResult = filteredResult.filter(element => element.featur_value?.size?.includes(sizeFilter));
            setTitle("All Products")
        }

        if (colorFilter !== "All" && colorFilter) {
            filteredResult = filteredResult.filter(element => element.featur_value?.color?.includes(colorFilter));
            setTitle("All Products")
        }

        if (typeFilter !== "All" && typeFilter) {
            filteredResult = filteredResult.filter(element => element.featur_value?.tile_type?.includes(typeFilter));
            setTitle("All Products")
        }
        if (sizeFilter === "All" && typeFilter === "All") {
            setTitle("Select Product")
        }
        setMainValue(filteredResult);
        handleShowTxture()
    };



    let isMenu = null;
    if (check_Type_Model) {
        isMenu = <MenuTextureMob txture={mainState} />
    } else {
        isMenu = <TextureMob txture={mainValue} />
    }

    let isShowTexture = "";
    if (showTxture) {
        isShowTexture = isMenu
    } else if (showTools) {
        isShowTexture = <ToolsMob
            uniqeValue={uniqeValue}
            uniqeTypeValue={uniqeTypeValue}
            uniqeColorValue={uniqeColorValue}
            setSizeFilter={setSizeFilter}
            setColorFilter={setColorFilter}
            setTypeFilter={setTypeFilter}
            sizeFilter={sizeFilter}
            colorFilter={colorFilter}
            typeFilter={typeFilter}
        />
    };


    const putAllProduct = () => {
        setTitle("Select Product")
        setSizeFilter("All")
        setColorFilter("All")
        setTypeFilter("All")
    }

    // let isSearchTexture = "";
    // if (txtureSearch) {
    //     isSearchTexture = <TextureSidebar txtureee={searchState}/>;
    // }
    // else{
    //     isSearchTexture = <BodySidebar/>
    // }

    return (
        <Fragment>
            <div className={footerBody ? 'footer-container footer-container-fadein' : 'footer-container'}>
                <div className='footer-header'>
                    <div className='footer-choice-product' onClick={() => {
                        if (title === "All Products") {
                            putAllProduct()
                        }
                        handleShowTxture();
                        if (!footerBody) showBodyFooter();
                    }}><p>{title}</p>
                    </div>
                    <div className='footer-tools' onClick={() => {
                        handleShowTools();
                        if (!footerBody) showBodyFooter();
                    }}><p>Filter</p>
                    </div>
                    <div className='footer-search'>
                        <Button type="botton" variant="none" onClick={handleShowSidebar} size="sm" className="fa fa-search w-100" />
                    </div>
                    <div className='footer-close'>
                        <Button type="botton" variant="none" onClick={showBodyFooter}><i className={footerBody ? 'fa fa-angle-down' : 'fa fa-angle-up'}></i></Button>
                    </div>
                </div>
                <div className='footer-body' style={footerBody ? { display: 'block' } : { display: 'none' }}>
                    {isShowTexture}
                </div>
            </div>
            <div className={showSidebar ? "sidebar d-block" : "sidebar d-none"}>
                <HeaderSidebar hSidebar={handleHideSidebar} handleSrch={handleSearchTecture} srchT={handleShowTextureSearch} />
                <TextureSidebar txtureee={searchState} hSidebar={handleHideSidebar} />
            </div>
        </Fragment>
    );
}

export default Footer;

{/* <span className={showTxture ? 'footer-underline active-underline' : 'footer-underline'}></span> */ }
{/* {isSearchTexture} */ }
{/* <FooterSidebar /> */ }


// const filterSizeHandler = (value) => {
//     handleShowTxture()
//     if (value === "All") {
//         setMainValue(takeTexture)
//     } else {
//         const newvalue = [...takeTexture]
//         const filterSize = newvalue.filter(element => element.featur_value?.size?.includes(value))
//         setMainValue(filterSize)
//     }
// }

// const filterTypeHandler = (value) => {
//     handleShowTxture()
//     if (value === "All") {
//         setMainValue(takeTexture)
//     } else {
//         const newvalue = [...takeTexture]
//         const filterType = newvalue.filter(element => element.featur_value?.tile_type?.includes(value))
//         setMainValue(filterType)
//     }
// }

// const filterColorHandler = (value) => {
//     handleShowTxture()
//     if (value === "All") {
//         setMainValue(takeTexture)
//     } else {
//         const newvalue = [...takeTexture]
//         const filterColor = newvalue.filter(element => element.featur_value?.color?.includes(value))
//         setMainValue(filterColor)
//     }
// }
