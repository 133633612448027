import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../Actions/setLoading';

const Picture404 = () => {
    // const dispatch = useDispatch()
    // useEffect(() => {
    //     setTimeout(() => {dispatch(setLoading());},1500)
    // },[dispatch])
    return (
        <div className='page_not_found'>
            <div className='page_not_found-body'>
                <img src='assets/images/handle-phone.png' alt='404-not found' />
            </div>
            <div className='page_not_found-caption'>
                <h1>sorry</h1>
                <p>The photo you took or uploaded has a quality or size problem</p>
                <p>Please follow the instructions above when taking pictures.</p>
            </div>
        </div>
    );
}

export default Picture404;