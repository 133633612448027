import React, { Fragment, useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { changedPicture } from '../../Actions/changePic';
import { allFeatureTexture } from '../../Actions/feature';
import { setLoading } from '../../Actions/setLoading';
import { setOldImg } from '../../Actions/setOldImg';
import { uploadImage } from '../../Services/transferData';
import FormImage from '../common/FormImage';
import { getPointsAction } from '../../Actions/pointsActions';
import Loading from '../common/Loading';

const UploadImage = () => {
    // TODO: STATE
    const [queryParams, setQueryParams] = useState(null);
    const [location, setLocation] = useState();
    const selectedMarker = useSelector(state => state.selectedMarker);
    const loadingState = useSelector(state => state.loading);
    // TODO: HOOKS
    const Redirect = useNavigate();
    const dispatch = useDispatch();



    useEffect(() => {
        import('../../utils/utils' /*webpackChunkName: "utils" */).then(({ getQueryParams, getLocation }) => {
            setQueryParams(getQueryParams());
            setLocation(getLocation());
        })
    }, []);


    // TODO: send sample photo to server function
    const handleSampleImage = async (uniqeId) => {
        dispatch(setLoading());
        const formData = new FormData();
        formData.append('default_img', uniqeId);//uniqeId = pic-1 || pic-2 | ...
        if (queryParams !== null) formData.append('procode', queryParams);
        formData.append('location', JSON.stringify(location));
        try {
            const { data, status } = await uploadImage(formData);
            if (status === 200) {
                dispatch(setLoading());
                dispatch(setOldImg(data.data.main_image));
                dispatch(changedPicture(data.data));
                localStorage.setItem("history", JSON.stringify({ [selectedMarker[0]]: data.data.object_id }));
                localStorage.setItem("inpage", true);
                dispatch(getPointsAction(data.points))
                dispatch(allFeatureTexture(data.featur))
                Redirect('/ariis-w');
            }
        } catch (error) {
            setTimeout(() => { dispatch(setLoading()) }, 1000)
        }
    }

    const setValuelocal = () => {
        localStorage.setItem("inpage", true);
    }


    useLayoutEffect(() => {
        if (loadingState) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
        }
        if (!loadingState) {
            document.body.style.overflow = "auto";
            document.body.style.height = "100%";
        }
    }, [loadingState]);


    return (
        <Fragment>
            <div className='Home-upload text-right mx-auto'>
                <div className='form-group w-100'>
                    <div className='w-50 text-center upload-descktop' onClick={setValuelocal}>
                        <label htmlFor="file-input">
                            <img src='assets/images/upload-img.png' alt='' />
                        </label>
                    </div>
                    <div className='w-50 text-center' onClick={e => handleSampleImage("pic-1")}>
                        <img src='assets/images/sample-img/1.jpg' alt='sample-image1' />
                        <p className='caption '>Drawing room</p>
                    </div>
                    <div className='w-50 text-center' onClick={e => handleSampleImage("pic-2")}>
                        <img src='assets/images/sample-img/2.jpg' alt='sample-image2' />
                        <p className='caption'>Kitchen</p>
                    </div>
                    <div className='w-50 text-center' onClick={e => handleSampleImage("pic-3")}>
                        <img src='assets/images/sample-img/3.jpg' alt='sample-image3' />
                        <p className='caption'>Kitchen</p>
                    </div>
                    <div className='w-50 text-center' onClick={e => handleSampleImage("pic-4")}>
                        <img src='assets/images/sample-img/4.jpg' alt='sample-image4' />
                        <p className='caption'>Yard</p>
                    </div>
                    <div className='w-50 text-center' onClick={e => handleSampleImage("pic-5")}>
                        <img src='assets/images/sample-img/5.jpg' alt='sample-image5' />
                        <p className='caption'>Drawing room</p>
                    </div>
                </div>
            </div>
            <div className='mx-auto text-white upload-mobile' onClick={setValuelocal}>
                <label htmlFor="file-input" >
                    <span className='d-inline-block' style={{ transform: "translate(0px, 15px)" }}>Upload Image<i className='fa fa-camera pe-2'></i></span>
                </label>
            </div>


            <FormImage queryParams={queryParams} location={location} />
            <ToastContainer />
            {loadingState ? <Loading /> : null}
        </Fragment>
    );
}
export default UploadImage;