import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CompareImg from '../common/CompareImg';
import Body from './body/Body';
import Footer from './footer/Footer';
import Header from './header/Header';
import LoadingFit from '../LoadingFit/LoadingFit';
import { useTextureContext } from '../../context';

const Mobile = () => {
    const textures = useSelector(state => state.texture);
    const picState = useSelector(state => state.picture);
    const oldPicState = useSelector(state => state.olgImg);
    const loadingState = useSelector(state => state.loading);
    const [showCompare, setShowCompare] = useState(false);
    const { mainTileName, mainTileSize, mainTileType } = useTextureContext();

    const handleShowCmpare = () => {
        setShowCompare(!showCompare);
    }

    return (
        <div className='mobile-container'>
            <div className='header w-100 d-inline-block'>
                <Header handleShowCmpare={handleShowCmpare} />
                {
                    mainTileName &&
                    <p className='text-center info-main-tail' style={{ fontSize: ".8rem" }}>{`${mainTileName.charAt(0).toUpperCase()}${mainTileName.slice(1)}`} / {mainTileSize} / {`${mainTileType.charAt(0).toUpperCase()}${mainTileType.slice(1)}`}</p>
                }
            </div>
            <div className='body w-100 d-inline-block css-nkyeax'>
                {
                    loadingState ?
                        <LoadingFit /> :
                        showCompare ? <CompareImg topImage={oldPicState} bottomImage={picState.img} /> : <Body Img={picState.img} />
                }

            </div>
            <div className='footer w-100 d-inline-block'>
                <Footer mainState={textures} />
            </div>
        </div>
    );
}

export default Mobile;