import _ from "lodash"

export const getPointsAction = (points) => {
    return async (dispatch) => {
        await dispatch({type:"GET_POINTS",payload: points})
    }
}

export const selectMarkerAction = (surfaceId,surfaceType) => {
    return async (dispatch,getState) => {
        const selectedSurfaceList = getState().selectedMarker;
        if (surfaceType === "wall") {
            await dispatch({type:"UN_SELECT_MARKER",payload: _.remove(selectedSurfaceList,(x) => {return x === 0})});
            if (!_.includes(selectedSurfaceList,surfaceId)) {
                let addSelectedSurfacePoint = _.concat(selectedSurfaceList,surfaceId);
                await dispatch({type:"SELECT_MARKER",payload:addSelectedSurfacePoint})
            }else{
                let removeSelectSurfacePoint = _.remove(selectedSurfaceList,(x) => {return x !== surfaceId});
                await dispatch({type:"SELECT_MARKER",payload:removeSelectSurfacePoint})
            }
            
        }else{
            await dispatch({type:"UN_SELECT_MARKER",payload:[]});
            await dispatch({type:"SELECT_MARKER",payload:[surfaceId]});
        }
    }
};

export const setTypeAction = (surfaceType) => {
    return async (dispatch,getState) => {
        if (surfaceType !== getState().surfaceType) {
            await dispatch({type:"TYPE_SURFACE",payload:surfaceType})
        }
    }
};

export const historyTextureAction = (selectedMarker,textureId) => {
    return async (dispatch,getState) => {
        const history_texture = getState().historySelectedTexture;
        const copy_history_texture = [...history_texture];
        let i = 0;
        do{
            if (_.includes(copy_history_texture[i],textureId)) {
                copy_history_texture[i][0] = selectedMarker;
                await dispatch({type:"UPDATE_HISTORY_TEXTURE",payload:copy_history_texture});
                i++
            }else{
                await dispatch({type:"ADD_HISTORY_TEXTURE",payload:{points:selectedMarker,object_id:+textureId}})
                i++;
            }

        }while(i < copy_history_texture.length)
        
    }
}