import { changedPicture } from "../Actions/changePic";
import { setLoading } from "../Actions/setLoading";
import { changeTexture } from "../Services/transferData";
import { store } from "../Store";


export const sendRotate = async (option,rotate,scale,featur_value,token,procode,object_id) => {
    const edited_featur_value = {};
    for (var key in featur_value){
        edited_featur_value[key] = featur_value[key][0];
    }
    const edited_option_value= {};
    for (var i = 0; i < option.length; i++) {
        var split = option[i].split(":");
        edited_option_value[split[0]] = split[1]
    }

    const data_texture = {
        option:edited_option_value,
        rotate,
        scale,
        featur_value:edited_featur_value,
        token,
        procode,
        object_id
    }
    try {
        const {data,status} = await changeTexture(data_texture);
        if (status === 200) {
            store.dispatch(changedPicture(data.data));
            store.dispatch(setLoading());
        }
    } catch (error) {
        store.dispatch(setLoading());
    }
}


export const sendScale = async (option,scale,featur_value,token,procode,object_id) => {
    const edited_featur_value = {};
    for (var key in featur_value){
        edited_featur_value[key] = featur_value[key][0];
    }
    const edited_option_value= {};
    for (var i = 0; i < option.length; i++) {
        var split = option[i].split(":");
        edited_option_value[split[0]] = split[1]
    }

    const data_texture = {
        option:edited_option_value,
        scale,
        featur_value:edited_featur_value,
        token,
        procode,
        object_id
    }
    if (scale >= 1 && scale <=9) {
        try {
            const {data,status} = await changeTexture(data_texture)
            if (status === 200) {
                store.dispatch(changedPicture(data.data));
                store.dispatch(setLoading());

            }
        } catch (error) {
            store.dispatch(setLoading());
        }
    }

}

export const sendFeature = async (option,rotate,scale,featur_value,token,procode,object_id) => {
    const edited_featur_value = {};
    for (var key in featur_value){
        edited_featur_value[key] = featur_value[key][0];
    }
    const edited_option_value= {};
    for (var i = 0; i < option.length; i++) {
        var split = option[i].split(":");
        edited_option_value[split[0]] = split[1]
    }
    const data_texture = {
        option:edited_option_value,
        rotate,
        scale,
        featur_value:edited_featur_value,
        token,
        procode,
        object_id
    }
        try {
            const {data,status} = await changeTexture(data_texture)
            if (status === 200) {
                store.dispatch(changedPicture(data.data));
                store.dispatch(setLoading());
            }
        } catch (error) {
            store.dispatch(setLoading());
        }
}