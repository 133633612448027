import React from "react";
import { Button, FormControl } from 'react-bootstrap'

const Search = ({ searchItem }) => {
  return (
    <div className="h-auto p-2" >
      <div className="d-flex">
        <FormControl
          type="search"
          placeholder="Search..."
          className="me-2 border-0 pe-2 ps-2 py-2 search-tail-input"
          aria-label="Search"
          variant="outline-secondary"
          onChange={(event) => searchItem(event.target.value)}
        />

        <Button variant="outline-secondary" className="fa fa-search border-0 py-2"></Button>
      </div>
    </div>
  );
}

export default Search;