import React from 'react';

const Errorboundary = () => {
    return (
        <div className='container h-100'>
            <div className='row h-100 text-center align-content-center'>
                <div className='col-md-12' >
                    <img src='assets/images/under-repair-transformed.png' className='col-12 col-md-4' alt='under-repair' />
                </div>
                <div className='col-md-12 mt-5'>
                    <h5>
                    Unfortunately, there is a problem.
                    </h5>
                    <a href='/' className='btn btn-primary mt-4 px-5'>Home</a>
                </div>
                
            </div>
        </div>
    );
}
 
export default Errorboundary;
