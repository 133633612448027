import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { selectMarkerAction, setTypeAction } from '../../../Actions/pointsActions';
import _ from 'lodash';

const Body = ({ Img }) => {
    // TODO: states
    const [lazy, setLazy] = useState(true);
    const [aspectRatio, setAspectRatio] = useState({});
    const [renderRatio, setRenderRatio] = useState({});
    const [marginTB, setMarginTB] = useState(0);
    const [marginLR, setMarginLR] = useState(0);
    const [showDots, setShowDots] = useState(true);

    const points = useSelector(state => state.points);
    const selected_marker = useSelector(state => state.selectedMarker);
    const dispatch = useDispatch()

    // Hooks
    const ref_rendet_ratio = useRef(null);

    useEffect(() => {
        const img = new Image();
        img.src = Img;
        img.onload = () => {
            if (lazy) setLazy(false);

            setAspectRatio({
                width: img.naturalWidth,
                height: img.naturalHeight
            });

            setRenderRatio({
                width: ref_rendet_ratio?.current?.clientWidth,
                height: ref_rendet_ratio?.current?.clientHeight,
            })
        }
    }, [Img]);

    useEffect(() => {

        const handleResize = () => {
            const img = new Image();
            img.src = Img;
            img.onload = () => {
                if (lazy) setLazy(false);

                setAspectRatio({
                    width: img.naturalWidth,
                    height: img.naturalHeight
                });

                if (ref_rendet_ratio.current) {
                    setRenderRatio({
                        width: ref_rendet_ratio.current.clientWidth,
                        height: ref_rendet_ratio.current.clientHeight,
                    });
                }
            }
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if ((aspectRatio.width / aspectRatio.height) >= (renderRatio.width / renderRatio.height)) {
            let margin_tb = renderRatio.height - ((renderRatio.width / aspectRatio.width) * aspectRatio.height);
            setMarginTB(margin_tb);
            setMarginLR(0);
        } else {
            let margin_lr = renderRatio.width - ((renderRatio.height / aspectRatio.height) * aspectRatio.width);
            setMarginLR(margin_lr);
            setMarginTB(0);
        }
    }, [aspectRatio, renderRatio])


    useEffect(() => {
        const handleClickOutside = (event) => {

            if (!event.target.classList.contains('dot-image')) {
                setShowDots(prevState => !prevState);
            }
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <>
            {lazy ? (<div className='w-100 h-100 text-center'>
                <img className='w-25 m-auto' src='assets/images/Eclipse-1s-200px.gif' alt="loading" />
                <p>Please wait...</p>
            </div>) : (
                <div className='w-100 position-relative' style={{ height: '98%' }} ref={ref_rendet_ratio}>
                    <TransformWrapper panning maxScale={2} disablePadding >
                        <TransformComponent>
                            <img src={Img} alt="test" />
                            <div className='w-100 h-100 position-absolute left-0 top-0'>
                                {points.map((point, index) => (
                                    <div key={index} role='button' className={`position-absolute dot-image ${showDots ? "" : "hidden-toggle"} `} style={{ left: `${((point.latitude.x / aspectRatio.width) * (renderRatio.width - marginLR)) + (marginLR / 2) - (32 / 2)}px`, top: `${((point.latitude.y / aspectRatio.height) * (renderRatio.height - marginTB)) + (marginTB / 2) - (32 / 2)}px` }} onClick={() => {
                                        dispatch(selectMarkerAction(point.id, point.type));
                                        dispatch(setTypeAction(point.type));
                                    }}>
                                        <img src={_.includes(selected_marker, point.id) ? 'assets/images/selected_surface_marker.svg' : 'assets/images/surface_marker.svg'} alt='point' style={{ width: '32px', height: '32px' }} />
                                    </div>
                                ))}
                            </div>
                        </TransformComponent>
                    </TransformWrapper>
                </div>
            )}
        </>
    );
}

export default Body;