export const setOptionAction = (option) => {
    return async (dispatch) => {
        await dispatch({type:"SET_OPTION",payload:option})
    }
}

export const clearOptionAction = () => {
    return async (dispatch) => {
        await dispatch({type:"CLEAR_OPTION"})
    }
}