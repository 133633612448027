import React, { useState, useEffect } from 'react';
import SideLeft from './sideLeft/SideLeft';
import SideRight from './sideRight/SideRight';
import { useNavigate } from 'react-router';
import Mobile from '../Mobile/Mobile'
const Web = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            localStorage.removeItem("inpage")
        }, 2000);
    }, []);

    useEffect(() => {
        const handleWindowResize = () => {
            if (!localStorage.getItem("inpage")) {
                localStorage.setItem("inpage", true);
            }
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    useEffect(() => {
        const inPage = localStorage.getItem("inpage");
        if (!inPage) {
            navigate("/");
        }
    }, []);



    return (
        <>{
            windowWidth < 600 ?
                <Mobile /> :
                <div className='w-100 d-flex web-container'>
                    <div className='shadow-sm  m-2 web-side-right' style={{ backgroundColor: '#ffffff', borderRadius: "10px" }}>
                        <SideRight />
                    </div>
                    <div className='web-side-left'>
                        <SideLeft />
                    </div>
                </div>
        }
        </>
    );
}

export default Web;