import React from 'react';
import { Button, Form } from 'react-bootstrap';

const HeaderSidebar = ({ hSidebar, handleSrch, srchT }) => {

    return (
        <div className='header-sidebar d-inline-block w-100'>
            <div className='header-title'>
                <p onClick={() => hSidebar(false)}><span className='fa fa-arrow-right ml-2 mx-2'></span>Advanced Search</p>
            </div>
            <div className='header-search'>
                <Form className="form-inline justify-content-center w-100" onSubmit={event => event.preventDefault()}>
                    <div className="input-group w-100">
                        <input type="search" placeholder="search..." onChange={(event) => {
                            handleSrch(event.target.value);
                            srchT(event.target.value);
                        }} name="search" aria-label="Search" className="form-control border-0 ps-3 pe-3" style={{ width: "85%" }} />
                        <div className="input-group-prepend" style={{ width: "15%" }}>
                            <Button type="submit" variant="none" onClick={() => hSidebar(false)} size="sm" className="fa fa-search w-100 py-3" />
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default HeaderSidebar;