import React from 'react';
import App from './containers/App';
import Errorboundary from './Componenets/common/Errorboundary';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './Store';
import {ErrorBoundary} from "react-error-boundary";
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap-icons/font/bootstrap-icons.css";

render(<ErrorBoundary FallbackComponent={Errorboundary}><Provider store={store}><App/></Provider></ErrorBoundary>,document.getElementById("root"));