import React, { useEffect, useRef, useState } from 'react';
import ReactCompareImage from 'react-compare-image';
import { useTextureContext } from '../../context'
const CompareImg = ({ topImage, bottomImage }) => {
    const { oldpic, NewPic } = useTextureContext()
    const [aspectRatio, setAspectRatio] = useState({});
    const [renderRatio, setRenderRatio] = useState({});
    const [marginLR, setMarginLR] = useState(0);
    console.log(oldpic, NewPic)

    // Hooks
    const ref_rendet_ratio = useRef(null);

    useEffect(() => {
        const img = new Image();
        img.src = bottomImage;
        img.onload = () => {
            setAspectRatio({
                width: img.naturalWidth,
                height: img.naturalHeight
            });

            setRenderRatio({
                width: ref_rendet_ratio.current.clientWidth,
                height: ref_rendet_ratio.current.clientHeight,
            })
        }
    }, [bottomImage]);

    useEffect(() => {
        let margin_lr = renderRatio.width - ((renderRatio.height / aspectRatio.height) * aspectRatio.width);
        setMarginLR(margin_lr);
    }, [aspectRatio, renderRatio]);


    return (
        <div className='w-100 position-relative  text-copo' style={{ height: "98%", overflow: 'hidden' }} ref={ref_rendet_ratio}>
            <div style={{ width: `${renderRatio.width - marginLR}px`, height: '100%', margin: '0 auto' }}>
                <ReactCompareImage aspectRatio='wider' leftImage={topImage} leftImageCss={{ objectFit: 'contain' }}
                    rightImageCss={{ objectFit: 'contain' }}
                    leftImageLabel={oldpic && !NewPic ? "" : oldpic}
                    rightImageLabel={NewPic}
                    rightImage={bottomImage} />
            </div>
        </div>
    );
}

export default CompareImg;