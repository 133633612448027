import _ from "lodash";

export const checkType = (state) => {
    const info = state[0];
    if (_.every(info,["type_obj","m"]) === true){
        return true;
    }else{
        return false;
    }
};

export const lvl1_menu = (state) => {
    const lvl1 = state[1];
    const menu = _.filter(lvl1, ['type_model', "m"]);
    const object = _.filter(lvl1, ['type_model', "o"]);
    return {
        m:menu,
        o:object
    }
}
export const lvl2_menu = (state) => {
    const lvl2 = state[2];
    const menu = _.filter(lvl2, ['type_model', "m"]);
    const object = _.filter(lvl2, ['type_model', "o"]);
    return {
        m:menu,
        o:object
    }
}
export const lvl3_menu = (state) => {
    const lvl3 = state[3];
    const menu = _.filter(lvl3, ['type_model', "m"]);
    const object = _.filter(lvl3, ['type_model', "o"]);
    return {
        m:menu,
        o:object
    }
}