import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CompareImg from '../../common/CompareImg';
import Header from './Header';
import Picture from './Picture';
import LoadingFit from '../../LoadingFit/LoadingFit';
const SideLeft = () => {
    const [showCompare, setShowCompare] = useState(false);

    const rendered_picture = useSelector(state => state.picture);
    const original_picture = useSelector(state => state.olgImg);
    const loadingState = useSelector(state => state.loading);
    const handleShowCmpare = () => {
        setShowCompare(prevState => !prevState);
    }
    return (
        <>
            <div className='side-left-header w-100 d-inline-block'>
                <Header handleShowCmpare={handleShowCmpare} />
            </div>
            {loadingState ?
                <LoadingFit /> :
                <div className='side-left-body w-100 d-inline-block css-nkyeax'>
                    {showCompare ?
                        <CompareImg topImage={original_picture} bottomImage={rendered_picture.img} /> :
                        <Picture Img={rendered_picture.img} />}
                </div>
            }
        </>
    );
}

export default SideLeft;