import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { downloadImage } from '../../../utils/downloadImage';
import { useSelector } from 'react-redux';
import FormImage from '../../common/FormImage';

const Header = ({ handleShowCmpare }) => {
    const picState = useSelector(state => state.picture);

    return (
        <div className='w-100 d-flex mt-1'>
            <div>
                <Button variant='secondery' className='py-2 rounded-3' onClick={e => downloadImage(picState.img, picState.name)}>
                    <small>Download</small><img src='assets/images/download-icon.png' alt='download' width='20' height='22' className='me-1' />
                </Button>
            </div>
            <div>
                <Button variant='none' className='py-2 rounded-3' onClick={() => handleShowCmpare()}>
                    <small>Comparison</small><img src='assets/images/compare_icon.png' width='20' height='22' alt='cart' className='me-1' />
                </Button>
            </div>
            <div>
                <label htmlFor="file-input">
                    <a rol='button' variant='none' className='btn py-2 rounded-3'>
                        <small>Retake</small><i className='fa fa-camera me-1'></i>
                    </a>
                </label>
            </div>
            <FormImage />
        </div>
    );
}

export default Header;