import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import Loading from '../Componenets/common/Loading';
import Picture404 from '../Componenets/common/Picture404';
import HomePage from '../Componenets/Home/HomePage';
import Web from '../Componenets/Web/Web';

const Ariis = () => {
    const loadingState = useSelector(state => state.loading);

    // useLayoutEffect(() => {
    //     if (loadingState) {
    //     document.body.style.overflow = "hidden";
    //     document.body.style.height = "100%";
    //     }
    //     if (!loadingState) {
    //     document.body.style.overflow = "auto";
    //     document.body.style.height = "100%";
    //     }
    // }, [loadingState]);

    return (
        <>
            <Routes>
                <Route path='/' exact element={<HomePage />} />
                <Route path='/ariis-w' element={<Web />} />
                <Route path='/404' element={<Picture404 />} />
            </Routes>
            <ToastContainer />
            {/* {loadingState ? <Loading/> : null} */}
        </>
    );
}

export default Ariis;