import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap';


const Filter = () => {
    const [typeBtn,setTypeBtn] = useState(false);
    const [colorBtn,setColorBtn] = useState(false);
    const [specificBtn,setSpecificBtn] = useState(false);

    const handleShowTypeFilter = () => {
        setTypeBtn(!typeBtn);
    }
    const handleShowColorFilter = () => {
        setColorBtn(!colorBtn);
    }
    const handleShowSpecificFilter = () => {
        setSpecificBtn(!specificBtn);
    }
    return ( 
        <>
            <div className='w-100 d-none'>
                <Button variant='light' onClick={handleShowTypeFilter}><img src='../assets/images/type-icon.png' className='btn-filter-icon' alt='type filter'/>نوع</Button>
                <Button variant='light' onClick={handleShowColorFilter}><img src='../assets/images/color-icon.png' className='btn-filter-icon' alt='color filter'/>رنگ</Button>
                <Button variant='light' onClick={handleShowSpecificFilter}><img src='../assets/images/specific-icon.png' className='btn-filter-icon' alt='specific filter'/>بافت</Button>
            </div>
            {/* <div className='modal-container'>
                <Modal
                    size="lg"
                    show={typeBtn}
                    onHide={handleShowTypeFilter}
                    aria-labelledby="example-modal-sizes-title-sm"
                    >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        نوع
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>...</Modal.Body>
                    <Modal.Footer>
                    <Button variant='none' onClick={handleShowTypeFilter}>اعمال فیلتر</Button>
                    <Button variant='none' onClick={handleShowTypeFilter}>حذف فیلتر</Button>
                    </Modal.Footer>
                </Modal>


                <Modal
                    size="lg"
                    show={colorBtn}
                    onHide={handleShowColorFilter}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        رنگ
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='none' onClick={handleShowColorFilter}>اعمال فیلتر</Button>
                        <Button variant='none' onClick={handleShowColorFilter}>حذف فیلتر</Button>
                    </Modal.Footer>
                </Modal>


                <Modal
                    size="lg"
                    show={specificBtn}
                    onHide={handleShowSpecificFilter}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        ویژگی‌ها
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='none' onClick={handleShowSpecificFilter}>اعمال فیلتر</Button>
                        <Button variant='none' onClick={handleShowSpecificFilter}>حذف فیلتر</Button>
                    </Modal.Footer>
                </Modal>
            </div> */}
  
        </>
    );
}
 
export default Filter;