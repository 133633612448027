import React, { useEffect } from 'react';
import { Figure } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import UploadImage from './UploadImage';
import { useTextureContext } from '../../context';

const HomePage = () => {
    const textures = useSelector(state => state.texture);
    const { setOldPic, setNewPic, setMainTileName, setMainTileSize, setMainTileType } = useTextureContext();

    useEffect(() => {
        setNewPic(null)
        setOldPic(null)
        setMainTileName(null)
        setMainTileSize(null)
        setMainTileType(null)
    }, [])

    return (
        <div className='home-page text-center'>
            <div className='home-page-powered'><a href={isEmpty(textures) ? "/" : `https://${textures[0][0].website}`} target='blank'>Powered by DECOEASE</a></div>
            <Figure className='mt-4'>
                <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src={'../assets/images/decoease-logo.png'}
                />
                <Figure.Caption className='fiqureCaption p-3'>
                    <b style={{ color: "#ff9f1c" }}>Decoease Smart Arrangement</b>
                </Figure.Caption>
            </Figure>
            <div className='home-header d-flex text-white mx-auto'>
                <div className='home-header-title'>
                    <div className='home-header-title-img'>
                        <img src='assets/images/Frame 1484.png' alt='' />
                    </div>
                    <div className='home-header-title-text'>
                        <span className='text-center w-100'>To compare the flooring in the real environment, upload a new photo or choose one of the images below</span>
                        {/* <a href="/" className='d-block'>How to use<i className='fa fa-long-arrow-right mx-2'></i></a> */}
                    </div>
                </div>
            </div>
            <UploadImage />
        </div>
    );
}

export default HomePage;