export const pointsReducer = (state = [],action) => {
    switch (action.type) {
        case 'GET_POINTS':
            return [...action.payload]
        default:
            return state;
    }
};

export const selectMarkerReducer = (state = [0],action) => {
    switch (action.type) {
        case "SELECT_MARKER":
            return [...action.payload]
        case "UN_SELECT_MARKER":
            return [...action.payload];
        default:
            return state;
    }
}

export const setTypeReducer = (state = "floor",action) => {
    switch (action.type) {
        case "TYPE_SURFACE":
            return action.payload;
        default:
            return state;
    }
};

export const historyTextureReducer = (state = [],action) => {
    switch (action.type) {
        case "ADD_HISTORY_TEXTURE":
            return [...state,action.payload];
        case "UPDATE_HISTORY_TEXTURE":
            return [...state];
        default:
            return state;
    }
}
