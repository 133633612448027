import { combineReducers } from "redux";
import { feacherReducer } from "./feature";
import { Loading } from "./loading";
import { OldImgReducer } from "./oldImage";
import { optionReducer } from "./option";
import { pictureReducer } from "./picture";
import { pointsReducer, selectMarkerReducer, setTypeReducer,historyTextureReducer } from "./pointsReducers";
import { rotateReducer } from "./rotate";
import { scaleReducer } from "./scale";
import { Texture } from "./texture";

export const Reducers = combineReducers({
    texture:Texture,
    loading:Loading,
    picture: pictureReducer,
    feature: feacherReducer,
    scale: scaleReducer,
    option: optionReducer,
    olgImg: OldImgReducer,
    rotate: rotateReducer,
    points: pointsReducer,
    selectedMarker: selectMarkerReducer,
    surfaceType: setTypeReducer,
    historySelectedTexture: historyTextureReducer
});